@import '../../vendors/variables';

.cursor {
  width: 40px;
  height: 40px;
  z-index: 9;
  border: 2px solid $theme;
  border-radius: 50%;
  position: absolute;
  top: -100px;
  left: -100px;
  transform: translate(-50%, -50%);
  pointer-events: none;
  background: transparent;
  opacity: 1;
  // transition: 0.15s cubic-bezier(.39,.575,.565,1);
}