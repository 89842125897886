@import '../../vendors/mixins';
@import '../../vendors/variables';


.projects {
  background: $black;
  .projectcontainer {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    grid-gap: 16px;
    margin-top: 120px;

    .project {
      @include padding(2);
      line-height: 1;
      color: $white;
      background: #252A30;
      position: relative;
      box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
      transition: 0.1s;

      .img {
        width: 100%;
      }

      .projecttitlecontainer {
        margin-top: 12px;
        @include flexaligncenter;
        justify-content: space-between;

        .projecttitle {
          color: $white;
          text-decoration: none;
          @include fontsize(3);
          font-weight: 700;
        }

        svg {
          color: $white;
          vertical-align: middle;
          margin-right: 8px;
          width: 20px;
          height: 20px;
        }
      }

      .description {
        margin-top: 12px;
        @include fontsize(1.9);
        letter-spacing: 0.5px;
        color: rgba($white, 0.8);
        line-height: 1.5;
      }

      .tags {
        margin-top: 12px;

        .tag {
          padding: 6px 12px;
          display: inline-block;
          margin-right: 4px;
          @include fontsize(1.5);
          font-weight: 700;
          border-radius: 4px;
          color: $white;
          border: 1px solid $theme;
        }
      }
    }
  }

  .findmore {
    margin: 20px auto;
    text-align: center;
    @include fontsize(2.5);

    a {
      color: $white;
    }
  }
}