@import '../../vendors/variables';

.footer {
  padding: 20px 0;
  text-align: center;
  background: $theme;
  color: $white;
  letter-spacing: 2px;
  cursor: default;

  svg {
    vertical-align: middle;
  }
}