@import '../../vendors/mixins';
@import '../../vendors/variables';

#view {
  max-width: 100%;
  background-attachment: fixed;

  .container {
    margin: auto;
    display: grid;
    grid-gap: 20px;
    @include padding(2);
    grid-template-columns: 1fr 2fr;
    place-items: center;

    .pic {
      position: relative;

      img {
        width: 250px;
        height: 250px;
        border-radius: 50%;
      }

      &::after {
        content: '';
        position: absolute;
        inset: 0;
        width: 230px;
        height: 230px;
        border: 0;
        border-radius: 50%;
        z-index: -2;
        transition: 0.15s;
      }

      &:hover::after {
        border: 10px solid $theme;
        z-index: 2;
        inset: -10px;
        width: 250px;
        height: 250px;
      }
    }

    .desc {
      padding: 20px;

      p {
        @include fontsize(2);
        color: #EEE;
        line-height: 1.6;
        opacity: 0.9;
        letter-spacing: 0.5px;
        position: relative;

        &::before {
          content: open-quote;
          position: absolute;
          top: -20px;
          left: -20px;
          color: $theme;
          @include fontsize(8);
          opacity: 0.4;
          line-height: 1;
        }
      }
    }

    .btn {
      margin-top: 40px;
    }
  }

  .container.codearchitects {
    margin-top: 200px;
    grid-template-columns: 2fr 1fr;

    .pic {
      img {
        border-radius: 0;
        width: 350px;
        height: auto;
      }

      &::after {
        content: none;
      }
    }

  }
}

@media screen and (max-width: 992px) {
  #view {
    padding: 60px 0;

    .container {
      grid-template-columns: 1fr;
      grid-gap: 12px;

      .pic img {
        width: 200px;
        height: 200px;
      }

      .desc {
        padding: 12px 24px;
        text-align: justify;
      }
    }

    .container.codearchitects {
      grid-template-columns: 1fr;
      grid-gap: 12px;
      margin-top: 100px;
      .pic img {
        width: 100%;
      }
    }
  }
}