@import '../vendors/variables';
@import '../vendors/mixins';

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: 'Inter', sans-serif;
  margin: 0 auto;
  //background: $white;
  background: linear-gradient(to right, #16161d, #000, #16161d);
  color: $white;
  overflow-x: hidden;
}

/* Common Elements*/
.section {
  padding: 80px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  z-index: 2;

  .container {
    max-width: 1400px;
    margin: 0 auto;
    width: 100%;
  }

  &:nth-child(even) {
    background: rgb(20, 20, 20);
  }
}

@media screen and (max-width: 992px) {
  .section {
    padding: 20px;
  }
}

.btn {
  display: inline-block;
  font-size: 0.9em;
  padding: 12px 32px;
  background: $theme;
  text-transform: uppercase;
  font-weight: 700;
  color: rgba($white, 1);
  cursor: pointer;
  text-decoration: none;
  transition: 0.1s;

  &:hover {
    background: $white;
    color: $black;
  }

  svg {
    vertical-align: middle;
    margin-right: 0.5em;
  }
}

.title {
  position: relative;
  text-align: center;
  text-transform: uppercase;
  @include fontsize(3);
  margin: 80px 0;

  &::after {
    content: attr(data-text);
    position: absolute;
    @include fontsize(12);
    color: $white;
    bottom: 0px;
    top: -40px;
    left: 0;
    right: 0;
    opacity: 0.2;
    transition: 0.1s;
  }

  &:hover {
    &::after {
      color: $theme;
    }
  }
}

@media screen and (max-width: 1200px) {
  .title::after {
    content: none;
  }
}

/* Selection placeholder */
::selection {
  background: $theme;
  color: $white;
}

.text span {
  background: linear-gradient($theme, $theme);
  padding: 2px 4px;
  color: $white;
  background-size: 0 100%;
  background-repeat: no-repeat;
  transition: background-size 0.5s ease-in;
}

.text span.active {
  background-size: 100% 100%;
}


/* Scrollbar */
// ::-webkit-scrollbar {
//     width: 10px;
// }

// ::-webkit-scrollbar-track {
//     background: rgb(40, 40, 40);
// }

// ::-webkit-scrollbar-thumb {
//     background: $white;
//     border-radius: 10px;
//     transition: 0.1s;
// }

// ::-webkit-scrollbar-thumb:hover {
//     background: rgba($theme, 1);
// }