@import '../../vendors/variables';
@import '../../vendors/mixins';

.header {
  background: $white;
  @include padding(0);

  .header-animate {
    text-align: center;
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
  }

  .wave {
    position: absolute;
    z-index: 2;
    bottom: -5px;
    left: 0;
    width: 100%;
    object-fit: cover;
    max-height: 250px;
    object-position: bottom;
  }

  h1 {
    @include fontsize(10);
    white-space: nowrap ;
    text-transform: uppercase;
    color: $black;
    display: inline-block;
    position: relative;
    &::after {
      content: '';
      position: absolute;
      width: 0;
      height: 5px;
      background: $theme;
      bottom: 5px;
      left: 0;
      transition: 0.5s;
    }
  }

  h1.active::after {
    width: 100%;
  }

  .subtitle {
    margin-top: 8px;
    font-size: 1.2em;
    color: rgb(120 109 109);
  }

  .social {
    margin-top: 8px;
    list-style: none;

    .sociallink {
      display: inline-block;
      margin: 0 8px;

      a {
        text-decoration: none;
        color: $black;
        font-size: 1.5em;

        svg {
          vertical-align: middle;
        }

        &:hover {
          color: $black;
        }
      }
    }
  }
}

@media screen and (max-width: 1000px) {
  .header {
    .social {
      margin: 0;
    }
  }
}

@media screen and (max-width: 800px) {
  .header {
    h1 {
      @include fontsize(6);
      &::after {
        bottom: 0;
      }
    }

    .subtitle, .social {
      @include padding(1);
    }
  }
}

@media screen and (max-width: 600px) {
  .header {

    h1 {
      @include fontsize(4);
      @include padding(0);
    }

    .subtitle {
      @include fontsize(2);
    }
  }
}